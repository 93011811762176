import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Columns, Container, Heading, Icon, Section, Table } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

const CreditNotesPage = () => (
  <Layout pro>
    
    <Section>
      <Container>
        <Columns>
          <Columns.Column>
            <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
              Notes de crédit
            </Heading>
            <Table.Container>
              <Table textAlign='right' hoverable size='fullwidth'>
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Numéro</td>
                    <td>Libellé</td>
                    <td>Montant HTT</td>
                    <td>Lien</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>31 Mai 2021</td>
                    <td>NC2156789</td>
                    <td>Votre note de crédit du 31 Mai 2021</td>
                    <td>{Math.round(Math.random() * 99999) / 100} €</td>
                    <td>
                      <Icon textColor='primary'>
                        <FontAwesomeIcon icon={faLink} />
                      </Icon>
                    </td>
                  </tr>
                  <tr>
                    <td>22 Mai 2021</td>
                    <td>NC2156776</td>
                    <td>Votre note de crédit du 22 Mai 2021</td>
                    <td>{Math.round(Math.random() * 99999) / 100} €</td>
                    <td>
                      <Icon textColor='primary'>
                        <FontAwesomeIcon icon={faLink} />
                      </Icon>
                    </td>
                  </tr>
                  <tr>
                    <td>21 Mai 2021</td>
                    <td>NC2156754</td>
                    <td>Votre note de crédit du 21 Mai 2021</td>
                    <td>{Math.round(Math.random() * 99999) / 100} €</td>
                    <td>
                      <Icon textColor='primary'>
                        <FontAwesomeIcon icon={faLink} />
                      </Icon>
                    </td>
                  </tr>
                  <tr>
                    <td>14 Mai 2021</td>
                    <td>NC2156733</td>
                    <td>Votre note de crédit du 14 Mai 2021</td>
                    <td>{Math.round(Math.random() * 99999) / 100} €</td>
                    <td>
                      <Icon textColor='primary'>
                        <FontAwesomeIcon icon={faLink} />
                      </Icon>
                    </td>
                  </tr>
                  <tr>
                    <td>5 Mai 2021</td>
                    <td>NC2156712</td>
                    <td>Votre note de crédit du 5 Mai 2021</td>
                    <td>{Math.round(Math.random() * 99999) / 100} €</td>
                    <td>
                      <Icon textColor='primary'>
                        <FontAwesomeIcon icon={faLink} />
                      </Icon>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Table.Container>
          </Columns.Column>
        </Columns>
      </Container>
    </Section >
  </Layout >
)

export default CreditNotesPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`